import { useMutation, useQuery } from "@apollo/client";
import { TrashIcon } from "@heroicons/react/24/outline";
import { DIRECT_TO_CONSUMER_ID } from "@soovu/shared/constants";
import { Filter } from "@soovu/shared/types";
import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";

import { defaultPageSize } from "../lib/page-size";
import {
  ADD_PARTNER_GROUP,
  DELETE_PARTNER_GROUP,
  GET_PARTNER_GROUPS,
} from "../queries/partner.queries";
import { GQLResponse } from "../types/common.types";
import { PartnerGroup } from "../types/patner-group.types";

import { Box } from "./Box";
import { Input } from "./Forms/Input";
import { Label } from "./Forms/Label";
import { Modal } from "./Modal";
import Table from "./Table";

interface EnrollmentsTableProps {
  filter?: Filter;
}

/* eslint-disable react/display-name */
export const GroupTable = forwardRef(
  ({ filter }: EnrollmentsTableProps, ref: any) => {
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
      pageIndex: 0,
      pageSize: defaultPageSize,
    });

    const pagination = React.useMemo(
      () => ({
        pageIndex,
        pageSize,
      }),
      [pageIndex, pageSize],
    );

    const { data, loading } = useQuery<{
      partnerGroups: GQLResponse<PartnerGroup>;
    }>(GET_PARTNER_GROUPS, {
      variables: {
        pagination: {
          page: pageIndex + 1,
          pageSize,
        },
        filter,
      },
    });

    const [addPartnerGroup] = useMutation(ADD_PARTNER_GROUP, {
      refetchQueries: [GET_PARTNER_GROUPS],
    });

    const [deletePartnerGroup] = useMutation(DELETE_PARTNER_GROUP, {
      refetchQueries: [GET_PARTNER_GROUPS],
    });

    const modalRef = useRef();
    const [isDefaultGroup, setIsDefaultGroup] = useState(false);
    const [groupId, setGroupId] = useState("");
    const [groupName, setGroupName] = useState("");
    const [groupIdError, setGroupIdError] = useState(false);
    const [groupNameError, setGroupNameError] = useState(false);

    const actionAddGroup = () => {
      (modalRef?.current as any)?.open();
    };

    useImperativeHandle(ref, () => ({
      actionAddGroup: actionAddGroup,
    }));

    const addGroup = (formData: FormData) => {
      if (groupId.length <= 0) {
        setGroupIdError(true);
        return false;
      }
      if (groupName.length <= 0) {
        setGroupNameError(true);
        return false;
      }
      console.log({
        variables: {
          groupId: groupId,
          name: groupName,
          partnerId: filter?.partnerId,
          isDefault: isDefaultGroup,
        },
      });
      addPartnerGroup({
        variables: {
          groupId: groupId,
          name: groupName,
          partnerId: filter?.partnerId,
          isDefault: isDefaultGroup,
        },
      });

      cancelGroup();
      return true;
    };

    const cancelGroup = () => {
      (modalRef?.current as any)?.close();
      setIsDefaultGroup(false);
      setGroupId("");
      setGroupName("");
    };

    if (loading || !data) {
      return <Box>...loading</Box>;
    }

    const columnHelper = createColumnHelper<PartnerGroup>();

    const allColumns = {
      name: columnHelper.accessor(
        (row) => {
          return row.name;
        },
        {
          cell: (info) => {
            const prefix =
              info.row.original.partnerId === DIRECT_TO_CONSUMER_ID
                ? "dtc"
                : "corporate";
            return (
              <Link
                to={`/${prefix}${prefix !== "dtc" ? "/" + info.row.original.partnerId : ""}?groupId=${info.row.original.groupId}`}
              >
                {" "}
                <Box
                  as={"span"}
                  css={{
                    color: "#EF4F25",
                    fontWeight: 350,
                    whiteSpace: "nowrap",
                  }}
                >
                  {info.getValue()}
                </Box>
              </Link>
            );
          },
          header: "Name",
        },
      ),
      groupId: columnHelper.accessor("groupId", {
        header: () => "Group Id",
      }),
      isDefault: columnHelper.accessor(
        (row) => {
          return row.isDefault;
        },
        {
          cell: (info) => (info.getValue() ? "Default" : ""),
          header: "Default",
        },
      ),
      delete: columnHelper.accessor(
        (row) => {
          return row.groupId;
        },
        {
          cell: (info) => {
            return (
              <a
                href="#"
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                  deletePartnerGroup({
                    variables: {
                      groupId: info.row.original.groupId,
                      partnerId: info.row.original.partnerId,
                    },
                  })
                }
                style={{
                  display: "flex",
                  width: "30px",
                  marginLeft: "auto",
                }}
              >
                <TrashIcon strokeWidth={2} />
              </a>
            );
          },
          header: "Delete",
          size: 30,
        },
      ),
    };

    const columns = [
      allColumns.name,
      allColumns.groupId,
      allColumns.isDefault,
      allColumns.delete,
    ];

    return (
      <>
        <Table
          columns={columns}
          data={data.partnerGroups}
          onPaginationChange={setPagination}
          pagination={pagination}
        />
        <Modal
          title="Add Group"
          submitText="Add"
          onOk={addGroup}
          onCancel={cancelGroup}
          ref={modalRef}
        >
          <Label>
            Default
            <Input
              type="checkbox"
              name="isDefault"
              checked={isDefaultGroup}
              onChange={(e) => setIsDefaultGroup(e.target.checked)}
            />
          </Label>
          <Label>Group Slug</Label>
          <Input
            type="text"
            name="groupId"
            value={groupId}
            css={groupIdError ? { border: "solid 1px rgb(239, 79, 37)" } : {}}
            onChange={(e) =>
              setGroupId(
                e.target.value
                  .toLowerCase()
                  .replaceAll(" ", "_")
                  .replace(/[^\w\d]/gm, ""),
              )
            }
          />
          <Label>Group Title</Label>
          <Input
            type="text"
            name="groupTitle"
            value={groupName}
            css={groupNameError ? { border: "solid 1px rgb(239, 79, 37)" } : {}}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </Modal>
      </>
    );
  },
);
