import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ArrowDownOnSquareIcon,
  ArrowDownOnSquareStackIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { DIRECT_TO_CONSUMER_ID, SUPER_ADMIN } from "@soovu/shared/constants";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { ActiveUsageTable } from "../components/ActiveUsageTable";
import { AgesChart } from "../components/AgesChart";
import { Box } from "../components/Box";
import { Divider } from "../components/Divider";
import { EngagementBarChart } from "../components/EngagementBarChart";
import { EnrollmentChart } from "../components/EnrollmentChart";
import { GainChart } from "../components/GainChart";
import { GenderChart } from "../components/GenderChart";
import { Grid } from "../components/Grid";
import { ImprovementChart } from "../components/ImprovementChart";
import { LocationChart } from "../components/LocationChart";
import { PainTypeChart } from "../components/PainTypeChart";
import { Panel } from "../components/Panel";
import { RatingChart } from "../components/RatingChart";
import { Search } from "../components/Search";
import { Tabs } from "../components/Tabs";
import { UserCount } from "../components/UserCount";
import { UserTable } from "../components/UserTable";
import { WaveformChart } from "../components/WaveformChart";
import { useTitle } from "../hooks/useTitle";
import { download_usage_report } from "../lib/download-usage-report";
import { GET_PARTNER_DETAILS } from "../queries/partner.queries";
import { COUNT_TREATMENTS } from "../queries/treatment.queries";
import { COUNT_USERS } from "../queries/user.queries";
import { OutcomeTemplate } from "../templates/outcome.template";
import { Partner } from "../types/partner.types";
import { ACCESS } from "../types/role.types";
import { TreatmentCountsResponse } from "../types/treatment.types";
import { UserCountsResponse } from "../types/user.types";

import { selectGroups } from "./CompanyDetails/select-groups";
import { sortAndPreparePartnerGroups } from "./CompanyDetails/sort-partner-groups";
import { TabGroups } from "./CompanyDetails/tab-groups";

export const DirectToConsumer = ({ access }: { access: ACCESS }) => {
  const { getAccessTokenSilently } = useAuth0();

  // Get the search parameters from the url
  const [searchParams, setSearchParams] = useSearchParams();

  // eslint-disable-next-line no-unused-vars
  const [partner, admin, phi] = access;

  const userTableRef = useRef<any>();

  useTitle("Direct to Consumer");

  // Breack out Group Ids to an array based on query params
  const groupIds: string[] | null =
    searchParams.get("groupId")?.split(",").sort() || null;
  if (groupIds !== null && groupIds.length > 0 && groupIds[0] === "") {
    groupIds[0] = "all";
  }
  console.log(groupIds);

  // GraphQL query to load Company Details
  const { data: partnerData, loading: partnerLoading } = useQuery<{
    partnerDetails: Partner;
  }>(GET_PARTNER_DETAILS, {
    variables: { id: DIRECT_TO_CONSUMER_ID },
  });

  // Sort and prepare the Company Groups and select out default groups if no filter exists
  const { sortedPartnerGroups, defaultSelectedGroups } = useMemo(() => {
    const result = sortAndPreparePartnerGroups(
      partnerData?.partnerDetails?.PartnerGroups || null,
      groupIds,
    );
    return {
      sortedPartnerGroups: result[0],
      defaultSelectedGroups: result[1],
    };
  }, [partnerData?.partnerDetails?.PartnerGroups, groupIds]);

  // Set state to select Company Groups
  const [selectedGroups, setSelectedGroups] = useState<string[] | null>(null);

  // Effect to track and update the selected Company Groups from the filter
  useEffect(() => {
    // Don't update if there is no change
    if (defaultSelectedGroups?.join(",") !== selectedGroups?.join(",")) {
      setSelectedGroups(defaultSelectedGroups);
    }
  }, [defaultSelectedGroups]);

  // Store selected Company Groups to avariable based on state availability
  const effectiveGroupIds = selectedGroups || defaultSelectedGroups || null;
  // console.log(effectiveGroupIds);

  const { data: userData, loading: userLoading } = useQuery<{
    userCounts: UserCountsResponse[];
  }>(COUNT_USERS, {
    variables: {
      filter: {
        partnerId: DIRECT_TO_CONSUMER_ID,
        groupIds: effectiveGroupIds,
      },
    },
    skip: partnerLoading,
  });

  const { data: treatmentData, loading: treatmentLoading } = useQuery<{
    treatmentCounts: TreatmentCountsResponse;
  }>(COUNT_TREATMENTS, {
    variables: {
      filter: {
        partnerId: DIRECT_TO_CONSUMER_ID,
        groupIds: effectiveGroupIds,
      },
    },
    skip: partnerLoading,
  });

  const userCount = userData?.userCounts[0];
  const treatmentCount = treatmentData?.treatmentCounts;

  const actionBulkEdit = () => {
    userTableRef.current.actionBulkEdit();
  };

  if (partnerLoading) {
    return <></>;
  }

  return (
    <>
      <Search />
      <Box as="h1">Direct to Consumer Enrollment</Box>
      <Box
        css={
          {
            width: "25vw",
          } as any
        }
      >
        {
          // Insert Company Group filters
          selectGroups(
            sortedPartnerGroups,
            effectiveGroupIds,
            (selected_groups: any) => {
              const selected_groups_values = selected_groups.map(
                (group: any) => group.value,
              );
              setSelectedGroups(selected_groups);
              setSearchParams({
                groupId: selected_groups_values.sort().join(","),
              });
            },
            true,
          )
        }
      </Box>
      <Grid>
        <UserCount
          count={userCount?.count}
          label="DTC Enrolled"
          loading={userLoading}
          monthly={userCount?.thisMonth}
        />
        <UserCount
          count={treatmentCount?.count}
          label="Total Treatments"
          loading={treatmentLoading}
          monthly={treatmentCount?.thisMonth}
        />
      </Grid>

      <Divider />
      <Box as="h2">Report Visuals</Box>

      <Tabs
        description="Different types of reports"
        tabs={[
          {
            id: "enrollment",
            label: "Enrollment",
            content: (
              <>
                <Grid>
                  <Panel title="Monthly Enrollments">
                    <EnrollmentChart
                      filter={{
                        partnerId: DIRECT_TO_CONSUMER_ID,
                        groupIds: effectiveGroupIds,
                      }}
                    />
                  </Panel>
                  <Panel title="Pain Types">
                    <PainTypeChart
                      filter={{
                        partnerId: DIRECT_TO_CONSUMER_ID,
                        groupIds: effectiveGroupIds,
                      }}
                    />
                  </Panel>
                </Grid>
                <Grid>
                  <Panel title="Gender">
                    <GenderChart
                      filter={{
                        partnerId: DIRECT_TO_CONSUMER_ID,
                        groupIds: effectiveGroupIds,
                      }}
                    />
                  </Panel>
                  <Panel title="Age">
                    <AgesChart
                      filter={{
                        partnerId: DIRECT_TO_CONSUMER_ID,
                        groupIds: effectiveGroupIds,
                      }}
                    />
                  </Panel>
                </Grid>
                <Divider />
                <Box as="h2">Individuals</Box>
                <Grid>
                  <Panel
                    title="Consumer Registrations"
                    buttons={
                      <a href="#" onClick={actionBulkEdit}>
                        <PencilSquareIcon strokeWidth={2} />
                      </a>
                    }
                  >
                    <UserTable
                      filter={{
                        partnerId: DIRECT_TO_CONSUMER_ID,
                        groupIds: effectiveGroupIds,
                      }}
                      partnerGroups={
                        sortedPartnerGroups
                          ? sortedPartnerGroups.slice(1)
                          : null
                      }
                      ref={userTableRef}
                    />
                  </Panel>
                </Grid>
              </>
            ),
          },
          {
            id: "engagement",
            label: "Engagement",
            content: (
              <>
                <Grid>
                  <Panel
                    title="Engaged by Month"
                    subTitle="% users that have had one or more HeatWave sessions per month"
                  >
                    <EngagementBarChart
                      filter={{
                        partnerId: DIRECT_TO_CONSUMER_ID,
                        groupIds: effectiveGroupIds,
                      }}
                    />
                  </Panel>
                  <Panel
                    title="Heat Sessions"
                    subTitle="By pain location since program initiation"
                  >
                    <LocationChart
                      filter={{
                        partnerId: DIRECT_TO_CONSUMER_ID,
                        groupIds: effectiveGroupIds,
                      }}
                    />
                  </Panel>
                  <Panel title="Improvement">
                    <ImprovementChart
                      filter={{
                        partnerId: DIRECT_TO_CONSUMER_ID,
                        groupIds: effectiveGroupIds,
                      }}
                    ></ImprovementChart>
                  </Panel>
                </Grid>
                <Grid>
                  <Panel title="Distribution Temperature">
                    <GainChart
                      filter={{
                        partnerId: DIRECT_TO_CONSUMER_ID,
                        groupIds: effectiveGroupIds,
                      }}
                    />
                  </Panel>
                  <Panel title="Distribution Heat Profiles">
                    <WaveformChart
                      filter={{
                        partnerId: DIRECT_TO_CONSUMER_ID,
                        groupIds: effectiveGroupIds,
                      }}
                    />
                  </Panel>
                </Grid>
                <Divider />
                <Grid>
                  {phi && (
                    <Panel
                      title="Usage Last 30 Days"
                      buttons={
                        <>
                          <a
                            href="#"
                            onClick={(
                              event: React.MouseEvent<HTMLAnchorElement>,
                            ) =>
                              download_usage_report(
                                event,
                                getAccessTokenSilently,
                                "30day",
                                "d2c",
                                effectiveGroupIds,
                              )
                            }
                          >
                            <ArrowDownOnSquareIcon strokeWidth={2} />
                          </a>
                          <a
                            href="#"
                            onClick={(
                              event: React.MouseEvent<HTMLAnchorElement>,
                            ) =>
                              download_usage_report(
                                event,
                                getAccessTokenSilently,
                                "full",
                                "d2c",
                                effectiveGroupIds,
                              )
                            }
                          >
                            <ArrowDownOnSquareStackIcon strokeWidth={2} />
                          </a>
                        </>
                      }
                    >
                      <ActiveUsageTable
                        filter={{
                          partnerId: DIRECT_TO_CONSUMER_ID,
                          groupIds: effectiveGroupIds,
                        }}
                      />
                    </Panel>
                  )}
                  {!phi && (
                    <>
                      <Panel title="Pain Rating Change">
                        <RatingChart
                          filter={{
                            partnerId: DIRECT_TO_CONSUMER_ID,
                            groupIds: effectiveGroupIds,
                          }}
                        />
                      </Panel>
                      <Panel></Panel>
                    </>
                  )}
                </Grid>
              </>
            ),
          },
          {
            id: "outcomes",
            label: "Outcomes",
            content: (
              <OutcomeTemplate
                filter={{
                  partnerId: DIRECT_TO_CONSUMER_ID,
                  groupIds: effectiveGroupIds,
                }}
              />
            ),
          },
          // If user is not an admin, remove ability to add and edit groups
          ...(!!partner && partner === SUPER_ADMIN
            ? [
                {
                  id: "groups",
                  label: "Groups",
                  content: (
                    <TabGroups partnerId={DIRECT_TO_CONSUMER_ID}></TabGroups>
                  ),
                },
              ]
            : []),
        ]}
      />
    </>
  );
};
