import { PlusCircleIcon } from "@heroicons/react/24/outline";
import React, { useRef } from "react";

import { Grid } from "../../components/Grid";
import { GroupTable } from "../../components/GroupTable";
import { Panel } from "../../components/Panel";

export const TabGroups = ({ partnerId }: { partnerId: string }) => {
  // Set reference to the Group Table to open Model
  const groupTableRef = useRef<any>();

  return (
    <Grid>
      <Panel
        title="Groups"
        buttons={
          <a
            href="#"
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
              (groupTableRef?.current as any)?.actionAddGroup()
            }
          >
            <PlusCircleIcon strokeWidth={2} />
          </a>
        }
      >
        <GroupTable filter={{ partnerId }} ref={groupTableRef} />
      </Panel>
    </Grid>
  );
};
