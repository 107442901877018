import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { SUPER_ADMIN } from "@soovu/shared/constants";
import React, { useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { Box } from "../components/Box";
import { BreadCrumb } from "../components/BreadCrumb";
import { Grid } from "../components/Grid";
import { Panel } from "../components/Panel";
import { Search } from "../components/Search";
import { Tabs } from "../components/Tabs";
import { useTitle } from "../hooks/useTitle";
import { GET_PARTNER_DETAILS } from "../queries/partner.queries";
import { COUNT_TREATMENTS } from "../queries/treatment.queries";
import { COUNT_USERS } from "../queries/user.queries";
import { OutcomeTemplate } from "../templates/outcome.template";
import { Partner } from "../types/partner.types";
import { ACCESS } from "../types/role.types";
import { TreatmentCountsResponse } from "../types/treatment.types";
import { UserCountsResponse } from "../types/user.types";

import { chartTotalCounts } from "./CompanyDetails/chart-total-counts";
import { selectGroups } from "./CompanyDetails/select-groups";
import { sortAndPreparePartnerGroups } from "./CompanyDetails/sort-partner-groups";
import { TabEngagement } from "./CompanyDetails/tab-engagement";
import { TabEnrollment } from "./CompanyDetails/tab-enrollment";
import { TabGroups } from "./CompanyDetails/tab-groups";

type CompanyDetailsProps = { access: ACCESS };

/**
 * Company / Partner Details Page
 * @param props {CompanyDetailsProps} Properties to pass to the Company Details Page
 *
 * @returns JSX.Element
 */
export const CompanyDetails = ({ access }: CompanyDetailsProps) => {
  // Validate authentication
  const { getAccessTokenSilently } = useAuth0();
  // Get the company id from the url parameters
  const { id: paramId } = useParams();
  // Get the search parameters from the url
  const [searchParams, setSearchParams] = useSearchParams();
  // Set the title of the page based on company details
  const [title, setTitle] = useState<string | undefined>();

  // Extract user access permissions
  const [partner, admin, phi] = access;
  const id = !!partner && partner !== SUPER_ADMIN ? partner : paramId;
  useTitle(title);

  // Breack out Group Ids to an array based on query params
  const groupIds: string[] | null =
    searchParams.get("groupId")?.split(",").sort() || null;
  if (groupIds !== null && groupIds.length > 0 && groupIds[0] === "") {
    groupIds[0] = "all";
  }
  // console.log(groupIds);

  // GraphQL query to load Company Details
  const { data: partnerData, loading: partnerLoading } = useQuery<{
    partnerDetails: Partner;
  }>(GET_PARTNER_DETAILS, {
    variables: { id },
  });

  // Sort and prepare the Company Groups and select out default groups if no filter exists
  const { sortedPartnerGroups, defaultSelectedGroups } = useMemo(() => {
    const result = sortAndPreparePartnerGroups(
      partnerData?.partnerDetails?.PartnerGroups || null,
      groupIds,
    );
    return {
      sortedPartnerGroups: result[0],
      defaultSelectedGroups: result[1],
    };
  }, [partnerData?.partnerDetails?.PartnerGroups, groupIds]);

  // Set state to select Company Groups
  const [selectedGroups, setSelectedGroups] = useState<string[] | null>(null);

  // Effect to track and update the selected Company Groups from the filter
  useEffect(() => {
    // Don't update if there is no change
    if (defaultSelectedGroups?.join(",") !== selectedGroups?.join(",")) {
      setSelectedGroups(defaultSelectedGroups);
    }
  }, [defaultSelectedGroups]);

  // Store selected Company Groups to avariable based on state availability
  const effectiveGroupIds = selectedGroups || defaultSelectedGroups || null;
  // console.log(effectiveGroupIds);

  // Load users associated with the Company, but skip query until partner details are loaded
  const { data: userData, loading: userLoading } = useQuery<{
    userCounts: UserCountsResponse[];
  }>(COUNT_USERS, {
    variables: {
      filter: {
        partnerId: id,
        groupIds: effectiveGroupIds,
      },
    },
    skip: partnerLoading,
  });

  // Load treatment associated with the Company, but skip query until partner details are loaded
  const { data: treatmentData, loading: treatmentLoading } = useQuery<{
    treatmentCounts: TreatmentCountsResponse;
  }>(COUNT_TREATMENTS, {
    variables: {
      filter: {
        partnerId: id,
        groupIds: effectiveGroupIds,
      },
    },
    skip: partnerLoading,
  });

  const partnerDetails = partnerData?.partnerDetails;
  const userCount = userData?.userCounts[0];
  const treatmentCount = treatmentData?.treatmentCounts;

  // Set the Bread crumbs based on partner details
  const breadCrumbParts = useMemo(() => {
    const parts = [];
    if (partnerDetails) {
      parts.push(
        { label: "Corporate Enrollment", path: "/corporate" },
        {
          label: partnerDetails.name,
          path: `/corporate/${partnerDetails.partnerUrl}`,
        },
      );
    }
    return parts;
  }, [partnerDetails]);

  useEffect(() => {
    setTitle(partnerDetails?.name);
  }, [partnerDetails]);

  if (partnerLoading) {
    return <></>;
  }

  return (
    <>
      {phi && <Search />}
      {!!partner && partner === SUPER_ADMIN && (
        <Grid>
          <Panel>
            <BreadCrumb parts={breadCrumbParts} />
          </Panel>
        </Grid>
      )}
      <Box
        css={
          {
            display: "flex",
            alignItems: "center",
          } as any
        }
      >
        <Box as="h1">{partnerData?.partnerDetails.name}</Box>
        <Box
          css={
            {
              width: "25vw",
              marginLeft: "16px",
            } as any
          }
        >
          {
            // Insert Company Group filters
            selectGroups(
              sortedPartnerGroups,
              effectiveGroupIds,
              (selected_groups: any) => {
                const selected_groups_values = selected_groups.map(
                  (group: any) => group.value,
                );
                setSelectedGroups(selected_groups);
                setSearchParams({
                  groupId: selected_groups_values.sort().join(","),
                });
              },
              true,
            )
          }
        </Box>
      </Box>
      <Grid>
        {chartTotalCounts(
          userCount,
          userLoading,
          treatmentCount,
          treatmentLoading,
        )}
      </Grid>
      <Tabs
        description="Different types of reports"
        tabs={[
          {
            id: "enrollment",
            label: "Enrollment",
            content: (
              <TabEnrollment
                phi={phi}
                partnerId={id}
                groupIds={effectiveGroupIds}
                partnerGroups={
                  sortedPartnerGroups ? sortedPartnerGroups.slice(1) : null
                }
              ></TabEnrollment>
            ),
          },
          {
            id: "engagement",
            label: "Engagement",
            content: (
              <TabEngagement
                phi={phi}
                partnerId={id}
                groupIds={effectiveGroupIds}
                getAccessTokenSilently={getAccessTokenSilently}
              ></TabEngagement>
            ),
          },
          {
            id: "outcomes",
            label: "Outcomes",
            content: (
              <OutcomeTemplate
                filter={{ partnerId: id, groupIds: effectiveGroupIds }}
              />
            ),
          },
          // If user is not an admin, remove ability to add and edit groups
          ...(!!partner && partner === SUPER_ADMIN
            ? [
                {
                  id: "groups",
                  label: "Groups",
                  content: <TabGroups partnerId={id}></TabGroups>,
                },
              ]
            : []),
        ]}
      />
    </>
  );
};
